<template>
  <div>

    <section>
    <h4>Qu'entend-t-on par « lumière naturelle » ?</h4>
    <p>La lumière du jour se décompose en deux parties complémentaires :</p>
    <ul>
      <li>
        une <strong>lumière directe</strong> venant de l'éclairage du soleil : il s'agit de
        l'ensoleillement ;
      </li>
      <li>
        une <strong>lumière diffuse</strong> venant de l'éclairage du ciel par l'atmosphère
        terrestre et les nuages permettant d'y voir « clair » dans la journée
        même si, par mauvais temps, les rayons solaires ne nous atteignent pas
        directement.
      </li>
    </ul>

    <p>
      Pour un bâtiment d'implantation déterminée, la quantité de lumière
      naturelle disponible dépend alors du type de ciel, du moment de l'année,
      de l'heure, de l'orientation de l'ouverture, de l'inclinaison de
      l'ouverture, de l'environnement physique (bâtiments voisins, ombrages,
      type de sol, végétation, etc).
    </p>
  </section>

    <section>
    <h4>Pourquoi est-ce aussi important ?</h4>
    <p>
      La lumière naturelle, contrairement à la lumière artificielle
      (l'éclairage), reste la plus adaptée au bon fonctionnement du corps humain
      :
      <strong
        >bien-être, confort, santé dans l'habitat, mais aussi productivité et
        concentration dans le tertiaire (bureaux, écoles)</strong
      >.
    </p>

    <p>Elle joue un rôle essentiel sur plusieurs aspects comme :</p>
    <ul>
      <li>
        la production de la sérotonine (« hormone du bonheur »), qui favorise un
        état mental positif et équilibré.
      </li>
      <li>
        la sécrétion d'hormones, qui jouent sur : la sensation d'endormissement
        et de fatigue (mélatonine), mais aussi l'éveil et l'humeur (cortisol).
      </li>
      <li>
        la production de vitamine D, qui participe au renforcement de
        l'organisme.
      </li>
    </ul>

    <p>De plus, un accès favorable à la lumière naturelle, limite les consommations énergétique dues à l'éclairage artificiel.</p>

    <p>
      Vrai facteur de performance humaine, environnementale, énergétique et
      économique, c'est donc une composante essentielle à considérer, et
      <strong
        >doit être pensée dès la phase amont de construction/rénovation d'un
        bâtiment</strong
      >.
    </p>

    <p>
      Mais attention ! Si les rayonnements solaires nous sont bénéfiques en
      hiver (amélioration du confort visuel, meilleure efficacité au travail,
      apports de chaleur, impact positif sur le moral etc.), ils peuvent aussi
      se révéler source de grand inconfort en saison chaude s'ils ne sont pas
      maîtrisés (problèmes de surchauffes ou encore d'éblouissement).
    </p>

    <p>
      C'est tout l'intérêt de notre outil qui alertera aussi sur ces différents
      aspects.
    </p>
  </section>

    <section>
    <h4>
      Pour mieux comprendre le sujet, consultez nos fiches pratiques et
      synthétiques dédiées :
    </h4>
    <div class="row">
      <div class="col-sm-12 col-lg-6 text-center">
        <a href="https://lab.cercle-promodul.inef4.org/tool_type/fiches-pratiques/tool/confort-et-sante-pourquoi-et-comment-favoriser-la-lumiere-naturelle-dans-les-batiments" target="_blank">
          <h6>
            Confort et santé : Pourquoi et comment favoriser la lumière naturelle
            dans les bâtiments ?
          </h6>
          <img
            src="https://lab.cercle-promodul.inef4.org/storage/uploads/ihTntW0eYe5ISPmxyxzuLCTQrfvrBpQp0kcDYOfs.png"
            alt="Confort et santé : Pourquoi et comment favoriser la lumière naturelle dans les bâtiments ?"
            style="width: 70%;"
          />
        </a>
      </div>
      <div class="col-sm-12 col-lg-6 text-center">
        <a href="https://lab.cercle-promodul.inef4.org/tool_type/fiches-pratiques/tool/confort-dans-les-batiments-apports-lumineux-et-confort-thermique" target="_blank">
          <h6>
            Confort dans les bâtiments : Apports lumineux et confort thermique
          </h6>
          <img
            src="https://lab.cercle-promodul.inef4.org/storage/uploads/vWhg6dSYV2SqLA7cmBaYQgagf8gfEBsmTahT0tE6.jpeg"
            alt="Confort dans les bâtiments : Apports lumineux et confort thermique"
            style="width: 70%;"
          />
        </a>
      </div>
    </div>
  </section>
  </div>
</template>
