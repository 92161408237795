<template>
  <div>
    <p>
      La lumière naturelle est une composante visible du rayonnement solaire, et
      est présente tout au long de la journée (du lever au coucher du soleil),
      même si certaines conditions météo ne permettent pas de voir le
      rayonnement solaire direct (les nuages assurent une filtration du
      rayonnement et diffuse la lumière visible).
    </p>

    <div>
      Dans un bâtiment, la lumière naturelle du jour peut être de trois types :
      <ul>
        <li>
          <strong>Lumière directe</strong>&nbsp;: venant de l'éclairage du soleil, c'est
          l'ensoleillement, une lumière vive et chaude&nbsp;;
        </li>
        <li>
          <strong>Lumière diffuse</strong>&nbsp;: venant de l'éclairage du ciel, lumière plus
          douce pouvant éclairer une pièce de manière plus homogène (même par
          mauvais temps)&nbsp;;
        </li>
        <li>
          <strong>Lumière réfléchi</strong>e&nbsp;: lumière qui éclaire les pièces en profondeur par
          réflexion de la lumière directe sur différentes surfaces&nbsp;;
        </li>
      </ul>
    </div>

    <p>
      Si le rayonnement solaire direct est capté par des parois verticales
      exposées du sud-est au sud-ouest, le rayonnement diffus est lui accessible
      y compris pour des parois exposées au nord.
    </p>

    <p>
      L'intensité lumineuse du rayonnement direct ou réfléchi reste plus élevée
      que l'intensité lumineuse du rayonnement diffus.
    </p>

    <div>
      Une répartition harmonieuse de la lumière naturelle dans un bâtiment /
      dans une pièce peut se faire via :
      <ul>
        <li>le type de distribution lumineuse (direct, indirecte) ;</li>
        <li>la répartition des ouvertures ;</li>
        <li>l'agencement des parois intérieures ;</li>
        <li>le matériau des surfaces du local ;</li>
        <li>les zones de distribution lumineuse ;</li>
        <li>les systèmes de distribution lumineuse.</li>
      </ul>
    </div>

    <p>Pour un confort lumineux optimal, il conviendra donc d'ajuster au mieux l'équilibre entre la captation du rayonnement direct, surtout l'hiver, mais aussi via une protection l'été, tout en favorisant également la captation maximale de rayonnement diffus.</p>

    <div class="text-center mt-2 mb-5">
      <img src="@/assets/img/helps/RayoDirectDiffRef1.png" alt="" class="img-fluid" style="max-width: 650px">
    </div>


  </div>
</template>
