<template>
  <div>
    <!-- <h3>L'unité de mesure « LUX » </h3> -->

    <p>
      L'éclairement (lux) correspond à l'éclairement d'une surface qui reçoit,
      perpendiculairement et uniformément répartie, un flux lumineux de 1 lumen
      par mètre carré.
    </p>

    <p>
      Dans la pratique, cette grandeur est utilisée pour caractériser une
      quantité de lumière dans un lieu ou sur une surface. Elle est
      particulièrement utile pour évaluer la quantité d'éclairage et la
      luminosité, c'est-à-dire la quantité de lumière perçue par un observateur
      humain face à un objet qui émet directement de la lumière (source primaire
      de lumière), ou par l'impression lumineuse ressentie en observant une
      surface éclairée par une source lumineuse, (source secondaire de lumière)
      comme la lune éclairée par le soleil).
    </p>

    <p>
      Par rapport à l'éclairage artificiel dans le bâtiment, les normes
      considèrent que, sur un espace de travail dans un bureau l'éclairement
      doit être de 500 lux ; il est de 800 lux dans une grande surface.
    </p>

    <p>
      Nos yeux peuvent s'accommoder à des niveaux d'éclairement très variables :
      de 100 000 lux pour une journée de soleil estivale, à moins de 1 lux pour
      une nuit de pleine lune. Un couloir ou un escalier reçoit généralement 100
      lux, la salle de bains et les WC sont à 200 lux, les pièces à vivre vont
      de 100 à 400 lux, et les espaces de travail, de 200 à 800 lux (pour en
      savoir plus : <a href="https://lab.cercle-promodul.inef4.org/knowledge/post/lumiere-naturelle-les-grandeurs-physiques-partie-2" target="_blank" >Lumière naturelle, les grandeurs physiques - partie 2</a>)
    </p>
  </div>
</template>
