<template>
<div>
  <p>
    Les masques horizontaux et verticaux désignent des éléments, liés au bâtiment
    lui-même, susceptibles de « masquer » une partie du rayonnement direct ou de
    générer des effets d'ombres atténuant l'accès à la lumière naturelle. Ces
    masques peuvent être horizontaux et situés au-dessus de la fenêtre
    (décrochement de façade, balcon, brises soleil fixes, marquises opaques,
    corniches) ou verticaux et situés de part et d'autre de la fenêtres (murets,
    murs de séparation, murs de soutènement, ressauts, etc.).
  </p>
    <div class="d-flex justify-content-evenly flex-wrap flex-lg-nowrap">
      <img
        src="@/assets/img/helps/MasquesProches.png"
        alt=""
        class="img-fluid"
        style="margin: 0.5rem; width:100%; min-width: 300px; max-width: 550px;"
      />
    </div>

</div>
</template>
