<template>
  <div>
    <section>
      <p>
        Basé sur le principe d'une saisie simplifiée (tenant compte des
        différentes caractéristiques du bâtiment, de la pièce ainsi que des
        ouvertures), l'outil permet d'évaluer le confort lumineux (qualité,
        quantité, rayonnement solaire) du local/pièce concerné.
      </p>

      <div>
        Cette évaluation tient également compte :
        <ul>
          <li>
            de la situation géographique du bâtiment, de la pièce / local ;
          </li>
          <li>de son orientation et de son environnement</li>
          <li>du rayonnement solaire direct, diffus et réfléchi.</li>
        </ul>
      </div>

      <p>
        L'outil propose donc un bilan simplifié relatif à la qualité, ou non, de
        votre confort lumineux ainsi que la proposition de préconisations en vue
        d'améliorer ce confort (au regard des éventuels inconforts mis en
        évidence par l'outil).
      </p>
    </section>
    <section>
      <h4>Pourquoi parle-t-on de la norme EN 17037 dans l'outil ?</h4>
      <p>
        C'est la première norme européenne qui traite spécifiquement de l'apport
        de lumière naturelle dans le bâtiment. Elle aborde différents aspects de
        la conception de l'éclairage naturel en établissant des niveaux minimaux
        de performance et des recommandations pour atteindre un niveau conforme
        « moyen » ou « élevé » dans chacun des quatre domaines suivants :
      </p>
      <ul>
        <li>Les apports en lumière naturelle au sein d'une pièce donnée ;</li>
        <li>La qualité de vue sur l'extérieur ;</li>
        <li>L'exposition à la lumière du jour ;</li>
        <li>L'éblouissement.</li>
      </ul>

      <p>
        L'outil se base en partie sur les standards de cette norme pour évaluer
        la qualité du rayonnement évalué, et ainsi proposer des recommandations
        éventuelles pour palier tout inconfort.
      </p>
    </section>

    <section>
      <h4>Bien comprendre les différents types de rayonnements solaires</h4>
      <p>
        La lumière naturelle est une composante visible du rayonnement solaire,
        et est présente tout au long de la journée (du lever au coucher du
        soleil), même si certaines conditions météo ne permettent pas de voir le
        rayonnement solaire direct (les nuages assurent une filtration du
        rayonnement et diffuse la lumière visible).
      </p>

      <p>
        Dans un bâtiment, la lumière naturelle du jour peut être de trois types
        :
      </p>
      <ul>
        <li><strong>Lumière directe</strong> : lumière vive et chaude ;</li>
        <li>
          <strong>Lumière diffuse</strong> : lumière plus douce pouvant éclairer
          une pièce de manière plus homogène ;
        </li>
        <li>
          <strong>Lumière réfléchie</strong> : lumière qui éclaire les pièces en
          profondeur par réflexion de la lumière directe sur différentes
          surfaces.
        </li>
      </ul>

      <p>
        A vocation pédagogique, l'outil intègre donc ces notions dans ses
        calculs afin de mieux comprendre l'importance de ces trois rayonnements,
        et notamment leurs impacts sur notre confort et santé.
      </p>
    </section>

    <section>
      <h4>Sources et principaux documents consultés :</h4>

      <ul>

        <li>
          <a
            href="https://lab.cercle-promodul.inef4.org/tool_type/guides-rapports-et-retours-dexperiences/tool/ameliorer-la-qualite-de-vie-et-le-confort-des-occupants-5-enseignements-a-retenir"
            target="_blank"
            >Améliorer la qualité de vie et le confort des occupants : 5 enseignements à retenir
          </a>
        </li>
        <li>
          <a
            href="https://enbau-online.ch/bauphysik/fr/5-3-calcul-de-leclairage-naturel/"
            target="_blank"
            >« Lumière naturelle », Physique du bâtiment
          </a>
        </li>
        <li>
          <a
            href="https://www.kingspan.com/fr/fr/actualites/comprendre-la-nouvelle-norme-en-17037/"
            target="_blank"
            >« Comprendre la nouvelle norme EN 17037 », Kingspan
          </a>
        </li>
        <li>
          <a
            href="https://www.bbs-slama.com/besoins-en-eclairage-naturel-le-facteur-de-lumiere-du-jour/"
            target="_blank"
            >« Besoins en éclairage naturel : le facteur de lumière du jour »,
            BBS SLAMA</a
          >
        </li>
        <li>
          <a
            href="https://www.afe-eclairage.fr/docs/2017/12/12/12-12-17-11-42-CLN_AFE_Conference_Norme_EN17037_31_octobre_2017.pdf"
            target="_blank"
            >« Projet de norme EN 17037 - L'éclairage naturel des bâtiments :
            contenu, impacts et cas pratiques », Collège Lumière Naturelle de
            l'AFE</a
          >
        </li>
        <li>
          <a
            href="https://www.bluetek.fr/fr/article/la-lumiere-naturelle-le-medicament-de-demain"
            target="_blank"
            >« La lumière naturelle, le médicament de demain ? », Bluetek</a
          >
        </li>
        <li>
          <a
            href="https://presse.velux.fr/la-lumiere-naturelle-notre-alliee-sante-au-quotidien/"
            target="_blank"
            >« La lumière naturelle, notre alliée santé au quotidien », Velux</a
          >
        </li>
        <li>
          <a
            href="https://presse.velux.fr/download/1175178/barometre-habitat-sain-2022.pdf"
            target="_blank"
            >« Baromètre de l'Habitat Sain 2022 », Velux</a
          >
        </li>
        <li>
          <a
            href="https://lab.cercle-promodul.inef4.org/tool_type/guides-rapports-et-retours-dexperiences/tool/ameliorer-la-qualite-de-vie-et-le-confort-des-occupants-5-enseignements-a-retenir"
            target="_blank"
            >Améliorer la qualité de vie et le confort des occupants : 5
            enseignements à retenir</a
          >
        </li>
        <li>
          <a
            href="https://lab.cercle-promodul.inef4.org/knowledge/post/lumiere-naturelle-les-grandeurs-physiques-partie-2"
            target="_blank"
            >Lumière naturelle, les grandeurs physiques</a
          >
        </li>
      </ul>
    </section>
  </div>
</template>
