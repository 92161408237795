<template>
  <div>
    <h4>Estimation des dimensions des masques lointains</h4>

    <p>
      Les dimensions des masques lointains ont un impact sur les apports de
      lumières naturelles Ils peuvent bloquées les rayons du soleil lors de sa
      trajectoire devant l'ouverture, mais aussi réduire l'apport en rayonnement
      diffus. Ils impactent également la qualité de la vue extérieur.
      <br />
      Pour cela il est important de les prendre en compte dans notre évaluation
      et bien les modéliser.
    </p>

    <p>Les distances demandées sont indiquées dans le schéma ci-dessous :</p>
    <div class="row">
      <div class="col-12 col-lg-7">
        <!-- <figure> -->
        <!-- <figcaption>Masque léger</figcaption> -->
        <img
          src="@/assets/img/helps/schema_masque_lointain_custom.png"
          alt="Masque léger"
          class="img-fluid"
          style="margin: 0.5rem"
        />
        <!-- </figure> -->
      </div>
      <div class="col-12 col-lg-5 fs-5 d-flex justify-content-center align-items-start pt-5">
        <!-- <div class=""> -->
          <ul class="list-group">
            <li class="list-group-item">
              A: Distance du point le plus éloigné
            </li>
            <li class="list-group-item">B: Distance du point le plus proche</li>
            <li class="list-group-item">C: Hauteur de l'obstacle</li>
          </ul>
        <!-- </div> -->
        <!-- <div>
          <hr />
          <p>
            Pour la hauteur de l'obstacle, dans le cas où il s'agit d'un
            immeuble, il est possible de compter le nombre d'étages et de le
            multiplier par 3 (hauteur sous plafond moyenne d'un immeuble) pour
            obtenir la hauteur en mètre.<br />
            <small
              >Exemple: Si l'immeuble a 5 étages, la hauteur globale sera
              d'environ 15 mètres (5 étages x 3 m = 15 mètres).</small
            >
          </p>

          <p>
            Pour les distances, il est possible d'évaluer avec le site Google
            Maps. Il suffit d'entrer l'adresse, un clique droit sur l'immeuble,
            «&nbsp;Mesurer une distance&nbsp;», puis cliquer sur le début de
            votre bâtiment
          </p>
        </div> -->
      </div>
    </div>

    <hr />

    <h4>Suggestion d'estimation approximative</h4>
    <h5>Hauteur (C)</h5>
    <div>
      <p>
        Dans le cas où il s'agit d'un immeuble,
        il est possible de compter le nombre d'étages (rez-de-chaussée compris) et de le multiplier par 3
        (hauteur moyenne d'un étage) pour obtenir la hauteur en
        mètre.<br />
        <small
          >Exemple: Si l'immeuble compte 5 étages, la hauteur globale sera d'environ 15 mètres (5 étages x 3 m = 15 mètres).</small
        >
      </p>

      <h5>Distances (A, B)</h5>
      <ol>
        <li>
          Aller sur
          <a href="https://www.google.fr/maps" target="_blank"
            >Google Maps <small>&copy;</small></a
          >
        </li>
        <li>Renseigner l'adresse du bâtiment concerné</li>
        <li>Faire un clic droit "Mesurer une distance"</li>
        <li>
          Placer les points correspondants aux extrémités de la distance à
          mesurer
        </li>
      </ol>
      <div class="row">
        <div class="col-12 col-lg-6">
          <img
            src="@/assets/img/helps/google-maps_1.jpg"
            alt="Masque léger"
            class="img-fluid rounded"
            style="margin: 0.5rem"
          />
        </div>
        <div class="col-12 col-lg-6">
          <img
            src="@/assets/img/helps/google-maps_2.jpg"
            alt="Masque léger"
            class="img-fluid rounded"
            style="margin: 0.5rem"
          />
        </div>
      </div>
    </div>
  </div>
</template>
