<template>
  <div>
    <!-- <h3>Quelques repères sur le positionnement des ouvertures dans un bâtiment</h3> -->

    <p><strong>Une orientation Nord</strong> bénéficie toute l'année d'une lumière homogène et du rayonnement solaire diffus. Il est judicieux de placer des ouvertures vers le nord lorsque le local nécessite une lumière homogène, peu variable ou diffuse ;</p>
    <p><strong>Une orientation Est</strong> profite du soleil le matin mais le rayonnement solaire est alors difficile à maîtriser car les rayons sont bas sur l'horizon. L'exposition solaire y est faible en hiver, cependant en été, l'orientation présente une exposition solaire supérieure à l'orientation sud ;</p>
    <p><strong>Une orientation Ouest</strong> assure une insolation directe en soirée Il est très intéressant d'orienter à l'ouest les locaux où l'on souhaite un éclairage doux et chaleureux. Toutefois, il y a un risque réel d'éblouissement et les gains solaires ont tendance à induire des surchauffes l'été (puisque les vitrages tournés vers l'ouest apportent des gains solaires l'après midi et en soirée) ;</p>
    <p><strong>Une orientation Sud</strong> entraîne un éclairement important. Les pièces orientées au sud bénéficient d'une lumière plus facile à contrôler et d'un ensoleillement maximal en hiver, car le soleil bas (environ 17°) pénètre profondément dans la pièce. En été, la hauteur solaire est plus élevée (60°) et la pénétration du soleil est donc moins profonde. Et contrairement à ce que l'on pourrait penser, les apports solaires l'été sur une surface verticale (et donc les risques de surchauffes) sont nettement inférieurs au sud qu'à l'est ou à l'ouest ou le soleil levant, ou couchant, est plus bas dans le ciel.</p>

  </div>
</template>