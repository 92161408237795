<template>
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 1000 1000"
    enable-background="new 0 0 1000 1000"
    xml:space="preserve"
  >
    <g>
        <polygon
          class="arrow-shape"
          points="177.051,326.702 500.029,5.5 823.007,326.702 661.519,326.702 661.519,1000 338.54,1000 338.54,326.702 177.051,326.702"
        />
      </g>
  </svg>
</template>
