<template>
  <div>
    <!-- <h3>Eblouissement</h3> -->

    <p>
      Capter la lumière du jour, en la recueillant pour éclairer naturellement
      un bâtiment et une pièce, est essentiel. Malgré tout, il est important,
      pour l'occupant, de se protéger de tout risque d'éblouissement en arrêtant
      partiellement ou totalement le rayonnement lumineux lorsque celui-ci
      présente des caractéristiques néfastes à l'utilisation d'un espace ou
      d'une pièce. Il faudra donc veiller à ce que la lumière (notamment le
      rayonnement direct) ne soit pas dirigé vers les yeux des occupants, ce qui
      peut créer une gêne.
    </p>

    <p>
      Pour information, la norme EN&nbsp;17037 a recours au DGP (Daylight Glare
      Probability), un indicateur d'éblouissement. La norme suggère ainsi des
      niveaux de DGP à ne pas dépasser plus de 5% du temps d'occupation.
    </p>

    <p>
      La norme recommande également l'utilisation de protections solaires
      mobiles ou rétractables, telles que des stores, pour limiter les risques
      d'éblouissement. Ces protections auront pour objectif d'empêcher le
      rayonnement solaire d'atteindre une surface que l'on ne souhaite pas voir
      ensoleillée.
    </p>

      <figure>
        <figcaption>Stratégie de la lumière naturelle</figcaption>
        <img
          src="@/assets/img/helps/Eblouissement.png"
          alt="Stratégie de la lumière naturelle"
          class="img-fluid"
        />
      </figure>
  </div>
</template>
