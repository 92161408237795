<template>
  <div>
    <!-- <h6>Eclairement utile de la lumière du jour</h6> -->
    <p>
      Il s'agit de déterminer la proportion d'éclairement naturel utile ou
      efficace sur une année. A la différence de l'indicateur sur l'autonomie
      lumineuse, où une valeur cible seuil est fixée selon la nature du local,
      l' éclairement utile de la lumière du jour, est calculé selon un
      intervalle de niveau d'éclairement jugé comme confortable par les
      utilisateurs. Il intègre donc des temps d'éclairement qui seraient
      inférieurs à la valeur cible et complétés le cas échéant par un éclairage
      artificiel complémentaire. Les études révèlent qu'à partir de 100 lux,
      complété par des éclairages légers, le niveau d'éclairement du local
      commenceraient à être satisfaisant. Au-delà de 2500 lux des risques
      d'éblouissement et d'inconfort sont ressentis par les occupantset dans ce
      cas nécessiterait l'occultation des ouvrants et sans doute le recours à un
      complément d'éclairage artificiel selon les cas. D'où la notion d'utilité
      de l'éclairage naturel se différenciant légèrement de celle de
      l'autonomie.
    </p>

    <p>
      De ce fait, cet indicateur présente un % qui en fonction du contexte
      (orientation, dimensions et position des ouvrants, etc.) peut-être
      supérieur ou inférieur à la valeur de l'autonomie lumineuse. Un idéal
      d'éclairement utile de lumière du jour dans les bâtiments tertiaires
      serait d'être entre 70% et 80% du temps d'occupation. Pour les bâtiments
      résidentiels il existe peut de normes sur ce point, les valeurs cibles et
      les temps d'occupation à prendre en considération. Par défaut dans l'outil
      nous avons retenu la période 6h/22h pour les bâtiments résidentiels
      correspondant aux valeurs standardisées d'occupation des réglementations
      thermiques.
    </p>

    <p>
      Pour information : cet indicateur est appelé UDI (Useful Daylight
      Illuminance) dans certains programme de certifications.
    </p>
  </div>
</template>
