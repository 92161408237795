import { createStore } from 'vuex';
import createPersistedState from "vuex-persistedstate";
import {orientationLabels} from '@/composition/orientations.js'
import {getTranslation} from '@/composition/translate.js'
import roomUsages from "@/json/roomUsage.json";

export default createStore({
  plugins: [createPersistedState({
    key: 'lumiere+_store',
    paths: ['version','simu'],
  })],
  state: {
    isDebug: false,
    version: {maj: null, min: null, rev: null},
    steps: [
      {id: 1, label:'Projet', icon:'', componentName: 'FormProject', isValid: false, isDirty: false, waitingMessage: 'Veuillez remplir le formulaire'},
      {id: 2, label:'Pièce', icon:'', componentName: 'FormRoom', isValid: false, isDirty: false, waitingMessage: 'Veuillez remplir le formulaire'},
      {id: 3, label:'Parois', icon:'', componentName: 'WallsMain', isValid: false, isDirty: false, waitingMessage: 'Veuillez ajouter au moins une fenêtre à votre projet et renseigner le type de vue associé'},
      {id: 4, label:'Masques lointains', icon:'', componentName: 'MasksMain', isValid: false, isDirty: false, waitingMessage: null},
      {id: 5, label:'Résultats', icon:'', componentName: 'ResultsMain', isValid: false, isDirty: false, waitingMessage: null},
    ],
    zone3D: {
      roomData: {
        shape: 1
      },
      hightlightItem: null
    },
    form3D: {
      supportType: 'walls',//walls, roofs
      supportId: null,
      itemType: null,
      itemId: null,
      doNotInit: null
    },
    simu: {
      "createdAt": null,
      "updatedAt": null,
      "project": {
        // "department": "02",
        // "zoneClimat": "H1a",
        // "localType": "MI",
        // "projectType": 1,
        // "buildingEnvironment": 1
      },
      "room": {
        // "roomUsage": 2,
        // "shape": 1,
        // "orientation": 0,
        // "roofInclination": 30,
        // "width": 5,
        // "length": 6,
        // "height": 2.8,
        // "level": 10,
        // "typeOfView": 1
      },
      masks: {
        N: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
        NE: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
        E: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
        SE: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
        S: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
        SW: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
        W: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
        NW: {
          easyMode: true,
          level: 0,
          distanceFarestPoint: -1,
          distanceClosestPoint: -1,
          obstacleHeight: -1,
        },
      },
      roomComponents: {
        walls: [
          {
            id: 1,
            orientation: "S",
            showWallLeft: false,
            showWallRight: false,
            isOutdoor: false,
            width: 0,
            height: 0,
            thickness: 0.2,
            typeOfView: []
          },
          {
            id: 2,
            orientation: "N",
            showWallLeft: false,
            showWallRight: false,
            isOutdoor: false,
            width: 0,
            height: 0,
            thickness: 0.2,
            typeOfView: []
          },
          {
            id: 3,
            orientation: "E",
            showWallLeft: false,
            showWallRight: false,
            isOutdoor: false,
            width: 0,
            height: 0,
            thickness: 0.2,
            typeOfView: []
          },
          {
            id: 4,
            orientation: "W",
            showWallLeft: false,
            showWallRight: false,
            isOutdoor: false,
            width: 0,
            height: 0,
            thickness: 0.2,
            typeOfView: []
          }
        ],
        roofs: [
          {id: 1, isVisible: false, isOutdoor: false, shape: 2, inclination: 45, thickness: 0.3, width: 0, height: 0, orientation: 'S'},
          {id: 2, isVisible: false, isOutdoor: false, shape: 2, inclination: 45, thickness: 0.3, width: 0, height: 0, orientation: 'N'},
          {id: 3, isVisible: false, isOutdoor: false, shape: 2, inclination: 45, thickness: 0.3, width: 0, height: 0, orientation: 'E'},
          {id: 4, isVisible: false, isOutdoor: false, shape: 2, inclination: 45, thickness: 0.3, width: 0, height: 0, orientation: 'W'},
        ],
        windows: [],
        caps: [],
        lowWalls: []
      }
    }
  },
  getters: {
    isProdMod () {
      const mode = process.env.VUE_APP_MODE
      // console.log('VUE_APP_MODE: ' + mode)
      return mode === 'production'
    },
    isDevMode () {
      return process.env.VUE_APP_MODE === 'development'
    },
    simu (state) {
      return state.simu;
    },
    isDebug (state) {
      return state.isDebug;
    },
    roomComponents (state) {
      return state.simu.roomComponents;
    },
    roomComponentsById: (state) => (itemType, itemId) => {
      return state.simu.roomComponents[itemType].find(item => item.id.toString() === itemId.toString());
    },
    roomComponents4Support: (state) => (itemType, supportType, supportId) => {
      const itemsTypeList = state.simu.roomComponents[itemType];
      if (!itemsTypeList) return [];
      return itemsTypeList.filter(item => (item.supportType && item.supportId && supportId) && item.supportType === supportType && item.supportId.toString() === supportId.toString());
    },
    roomComponentsGetSupport: (state) => (itemType, itemId) => {
      // console.log('roomComponentsGetSupport A', itemType, itemId);
      const item = state.simu.roomComponents[itemType].find(item => item.id.toString() === itemId.toString());
      // console.log('roomComponentsGetSupport B', item);
      if (!item.supportType || !item.supportId) {
        return null;
      }
      const data = state.simu.roomComponents[item.supportType].find(support => support.id === item.supportId);
      // console.log('roomComponentsGetSupport 1', data);

      return {
        type: item.supportType,
        data: data
      }
    },
    roomData (state) {
      return state.simu.room;
    },
    roomShape (state) {
      return state.simu.room.shape;
    },
    roomNbRoofs (state) {
      return state.simu.roomComponents["roofs"].length;
    },
    roomUsageLuxReco (state) {
      const roomUsageSImu = state.simu.room.roomUsage;
      const roomUsageLuxReco = roomUsages.find(
        (roomUsage) => roomUsage.value === roomUsageSImu
      );
      return roomUsageLuxReco.luxReco;
    },
    roomUsageLux (state) {
      const roomUsageSimu = state.simu.room.roomUsage;
      const roomUsageLuxReco = roomUsages.find(
        (roomUsage) => roomUsage.value === roomUsageSimu
      );
      return {
        min: roomUsageLuxReco.luxMin,
        max: roomUsageLuxReco.luxMax,
        reco: roomUsageLuxReco.luxReco
      }
    },
    form3D (state) {
      return state.form3D;
    },
    walls (state) {
      return state.simu.roomComponents.walls;
    },
    wallsOutdoor (state) {
      return state.simu.roomComponents.walls.filter(wall => wall.isOutdoor);
    },
    roofs (state) {
      return state.simu.roomComponents.roofs;
    },
    roofsVisibles (state) {
      return state.simu.roomComponents.roofs.filter(roof => roof.isVisible);
    },
    roofsOutdoor (state) {
      return state.simu.roomComponents.roofs.filter(roof => roof.isOutdoor);
    },
    masks (state) {
      return state.simu.masks;
    },
    windows (state) {
      return state.simu.roomComponents.windows;
    },
    windowsWithSupportName (state, getters) {
      // const windows = state.simu.roomComponents.windows;
      const windowsList = [];
      const supportTypeList = ['walls', 'roofs'];
      supportTypeList.forEach((supportType) => {
        const supportItems = state.simu.roomComponents[supportType];
        supportItems.forEach((supportItem) => {
          const windows = getters.roomComponents4Support('windows', supportType, supportItem.id);
          const orientation = supportItem.orientation;
          const orientationLabel = orientationLabels(orientation);
          windows.forEach((window, windowIndex) => {
            windowsList.push({
              id: window.id,
              supportType: supportType,
              supportId: supportItem.id,
              windowIndex: windowIndex,
              orientation: orientation,
              orientationLabel: orientationLabel,
              // label: `${getTranslation(supportType, true)} ${supportItem.id} (${orientationLabel}), Fen. ${windowIndex + 1}`
              label: `${getTranslation(supportType, true)} (${orientationLabel}), Fen. ${windowIndex + 1}`
            })
          })
        })

      })
      
      // windows.forEach((window) => {
      //   const support = getters.roomComponentsGetSupport('windows', window.id);
      //   const orientation = support.data.orientation;

      //   windowsList.push({
      //     id: window.id,
      //     supportType: support.type,
      //     supportIndex: 
      //     orientation: orientationLabels(orientation),
      //     label: `${getTranslation(support.type)} `
      //   })
      // })

      return windowsList;
    },
    orientationsInUse (state, getters) {
      const windowList = getters.windowsWithSupportName;
      let orientationsList = [];
      windowList.forEach((window) => {
        if (!orientationsList.includes(window.orientation)) {
          orientationsList.push(window.orientation);
        }
      })
      return orientationsList;
    }

  },
  mutations: {
    SET_FORM_3D (state, payload) {
      // alert('AAAA');
      // console.log("SET_FORM_3D", payload);
      state.form3D = payload;
    },
    SAVE_SIMU_FORM (state, payload) {
      const form = payload.form;
      const index = payload.index;
      const data = payload.data;
      if (index !== null && index !== undefined) {
        state.simu[form][index] = data;
      } else {
        state.simu[form] = data;
      }

      if (!state.simu.createdAt) {
        state.simu.createdAt = Date.now();
      }
      state.simu.updatedAt = Date.now();
      // console.log("SAVE_SIMU_FORM", payload);
      // console.log("createdAt", state.simu.createdAt);
      
    },
    SHOW_ROOF_PANEL (state, payload) {
      // console.log("SHOW_ROOF_PANEL", payload);
      
      const roofIndex = payload.roofIndex;
      const isVisible = payload.isVisible;
      if (!state.simu.roomComponents.roofs[roofIndex]) return false;
      state.simu.roomComponents.roofs[roofIndex].isVisible = isVisible;
    },
    REMOVE_ROOM_COMPONENT (state, payload) {
      const type = payload.type;
      const index = payload.index;
      if (type === 'walls' || type === 'roofs') {
        console.error('DO NOT REMOVE WALL OR ROOF !');
        return false;
      }
      state.simu.roomComponents[type].splice(index, 1);
      return true;
    }
  },
  actions: {
    showRoofPanel ({ dispatch, commit, getters }, payload) {
      // console.log("showRoofPanel", payload);


      const roofId = payload.roofId;
      const isVisible = payload.isVisible;
      const roofIndex = getters.roofs.findIndex(roof => roof.id === roofId);

      if (!isVisible) {
        if (!confirm('Confirmez-vous la suppression de ce pan de toiture ?')) return;
        getters.windows.forEach( (win, winIndex) => {
          if (win.supportType === 'roofs' && win.supportId === roofId) {
            commit('REMOVE_ROOM_COMPONENT', {type: 'windows', index: winIndex});
          }
        });
        commit('SHOW_ROOF_PANEL',{roofIndex, isVisible});
      } else {
        commit('SHOW_ROOF_PANEL',{roofIndex, isVisible});
        commit('SET_FORM_3D', { supportType: 'roofs', supportId: roofId});
      }

      
    },
    removeRoomComponent ({ commit, getters}, payload) {
      const type = payload.type;
      const id = payload.id;
      const index = getters.roomComponents[type].findIndex(comp => comp.id === id);
      commit('REMOVE_ROOM_COMPONENT',{type, index});
    },
    removeRoomComponentFromSupport ({ dispatch, getters }, payload) {
      const supportType = payload.type;
      const supportId = payload.id;
      const roomComponentsType = ['windows' , 'lowWalls', 'caps'];
      roomComponentsType.forEach((rct) => {
        if (getters.roomComponents[rct].length > 0) {
          getters.roomComponents[rct].forEach((item) => {
            if (item.supportType === supportType && item.supportId === supportId) {
              dispatch('removeRoomComponent', {type: rct, id: item.id});
            }
          });
        }
      });

    }
  },
  modules: {}
})
