<template>
  <div>
    <section>
      <p>
        En plus des critères d'exposition au soleil direct, la norme EN17037 met
        en avant l'importance des vues vers l'extérieur.
      </p>
    </section>

    <section>
      <h5>Qu'entend-on par « vue sur l'extérieur » ?</h5>

      <p>
        La qualité des vues vers l'extérieur assure une connexion visuelle avec
        l'environnement extérieur pour fournir des informations sur
        l'environnement local, les changements météorologiques et l'heure de la
        journée. La norme l'évalue selon les critères suivants : la taille des
        ouvertures, l'angle horizontal depuis un point de référence à
        l'intérieur d'une pièce, la distance entre les ouvertures et les
        obstacles extérieurs les plus proches, le nombre minimum de composantes
        de la vue (ciel, paysage urbain ou naturel, sol). L'évaluation se fait à
        partir de points de référence sélectionnés correspondant à la position
        des personnes dans la surface utile.
      </p>

      <p>
        Avoir une estimation pertinente de ces éléments est donc essentiel pour
        le confort et la santé des occupants.
      </p>

      <small
        >Source : Norme EN 17037 « Lumière naturelle dans les bâtiments »</small
      >

      <div class="text-center my-5">
        <img
          src="@/assets/img/helps/typeOfView1.png"
          alt="Schéma vue extérieure"
          class="img-fluid"
          style="width: 85%"
        />
      </div>

      <p>
        Aussi, reculer jusqu'à la moitié de la profondeur de la pièce permet d'obtenir une évaluation plus
        pertinente de la qualité de la vue sur l'extérieur. En effet, par
        défaut, depuis une ouverture, ces trois éléments sont très souvent très
        perceptibles, mais le sont beaucoup moins, voire pas du tout, si l'on se
        positionne au point opposé à l'ouverture.
      </p>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelpDummyA",
};
</script>
