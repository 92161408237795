<template>
  <div>
    <!-- <h3>La norme EN 17037 </h3> -->

    <p>
      La méthode de calcul utilisée dans l'outil est inspirée de la norme EN&nbsp;17037 et reprend quelques critères, notamment des 3 niveaux de
      recommandations minimales d'éclairage naturel à atteindre.
    </p>

    <p>
      Pour information, la norme EN&nbsp;17037 est la première norme européenne qui
      traite spécifiquement de l'apport de lumière naturelle dans le
      bâtiment&nbsp;:
    </p>

    <p>
      Elle fournit des indicateurs pour apprécier le confort visuel et des
      recommandations sur la performance de l'éclairage naturel à obtenir. Elle
      préconise des recommandations sur la durée d'exposition à l'ensoleillement
      direct à l'intérieur des bâtiments. Elle fournit des informations sur la
      façon d'exploiter l'éclairage naturel ainsi que les moyens de réduire
      l'éblouissement.
    </p>

    <p>
      De plus, elle définit les systèmes de mesure utilisés pour évaluer les
      conditions d'éclairage naturel et fournit les principes de calcul et de
      vérification. Ces principes permettent de traiter la question de la
      variabilité de la lumière naturelle au fil des jours et de l'année.
    </p>

    <p>
      Cette notion de variabilité de l'éclairage naturel sur une année est
      importante, car il convient que la lumière naturelle éclaire les espaces
      durant un nombre significatif d'heures sur <strong>l'ensemble</strong> de
      l'année.
    </p>

    <div>
      En effet, l'apport en lumière naturelle n'est pas uniforme et
      dépend&nbsp;:
      <ul>
        <li>
          de la disponibilité de la lumière naturelle à l'extérieur
          (c'est-à-dire le climat du site concerné – ciel, moment de l'année,
          heure en journée)&nbsp;;
        </li>
        <li>
          de paramètres extérieurs l'influençant, comme le milieu environnant du
          bâtiment (ombres, masques, paysages)&nbsp;;
        </li>
        <li>
          de paramètres inhérents au bâtiment, comme l'orientation des façades,
          la taille / forme / position des ouvertures (verticales, inclinées ou
          zénithales), ainsi que de l'agencement des espaces intérieurs.
        </li>
      </ul>
    </div>
  </div>
</template>
