<template>
  <div>
    <!-- <h3>situation géographique</h3> -->

    <p>
      La situation géographique d'une pièce ou d'un local a une incidence sur le
      rayonnement solaire reçu tout au long de l'année, dans un endroit donné.
    </p>

    <p>
      Cela est dû à l'axe de rotation de la Terre, inclinée de 23,5°C par
      rapport à son plan d'orbite autour du soleil. Ainsi, l'hémisphère incliné
      a des journées plus longues, une élévation du soleil plus importante et un
      rayonnement plus intense, créant alors les saisons : les journées
      bénéficieront d'un meilleur ensoleillement en juin (été) plutôt qu'en
      janvier (hiver) en France par exemple.
    </p>

    <div class="d-flex justify-content-evenly flex-wrap flex-lg-nowrap">
      <img
        src="@/assets/img/helps/situation_geo_1.png"
        alt=""
        class="img-fluid"
        style="margin: 0.5rem"
      />
    </div>

    <div class="d-flex justify-content-evenly flex-wrap flex-lg-nowrap">
      <img
        src="@/assets/img/helps/situation_geo_2.png"
        alt=""
        class="img-fluid"
        style="margin: 0.5rem"
      />
      <img
        src="@/assets/img/helps/situation_geo_3.png"
        alt=""
        class="img-fluid"
        style="margin: 0.5rem"
      />
    </div>

    <p>
      L'élévation du soleil variant selon les saisons, les rayonnements perçus
      au sein d'une même pièce seront donc différents en janvier et en juin. La
      présence de masques solaires devant les ouvertures aura également un
      impact différent selon les périodes de l'année.
    </p>

    <div
      class="d-flex justify-content-evenly align-items-start flex-wrap flex-lg-nowrap"
    >
      <div>
        <p>
          Mais les conditions météorologiques dominantes (ensoleillement, pluie,
          nuageux etc.) ont également un effet sur la quantité d'ensoleillement
          perçu.
        </p>
        <p>
          Il est ainsi possible de déterminer une carte solaire de
          l'ensoleillement en France métropolitaine. Le nombre d'heures total
          d'ensoleillement sur une année est ainsi représenté : les départements
          du bassin méditerranéen, ainsi que la Corse, bénéficient ainsi d'un
          rayonnement solaire plus important.
        </p>
      </div>
      <img
        src="@/assets/img/helps/situation_geo_4.png"
        alt=""
        class="img-fluid"
        style="margin: 1rem"
      />
    </div>

    <fieldset>
      <legend>En savoir plus :</legend>
      <ul>
        <li>
          <a href="https://www.youtube.com/watch?v=Ps1UPv4ETRk" target="_blank"
            >Pourquoi y-a-t-il des saisons ?</a
          >
        </li>
        <li>
          <a href="https://www.youtube.com/watch?v=yARKHaPmE0o" target="_blank"
            >Solstices, équinoxes et précession</a
          >
        </li>
      </ul>
    </fieldset>
  </div>
</template>
