<template>
  <ul class="social-networks links">
    <li class="link-item">
      <a class="link" href="https://lab.cercle-promodul.inef4.org/" target="_blank" rel="noopener" aria-label="LeLAB" title="Le LAB">
        <icon-base class="app-icon" :width="35" :height="18" view-box="0 0 512 225" icon-name="Le LAB">
          <icon-logo-le-lab />
        </icon-base>
      </a>
    </li>
    <li class="link-item link-item-separator">
      <a class="link" href="https://cercle-promodul.inef4.org/" target="_blank" rel="noopener" aria-label="cercle-promodul.inef4.org" title="Fonds de dotation Cercle Promodul / INEF4">
        <!-- <i class="fas fa-globe"></i> -->
        <icon-base class="app-icon" :width="25" :height="25" view-box="0 0 512 512" icon-name="Fonds de dotation Cercle Promodul / INEF4">
          <!-- <icon-cercle-promodul /> -->
          <icon-logo-cercle-promodul />
        </icon-base>
      </a>
    </li>
    <li class="link-item">
      <a class="link" href="https://www.linkedin.com/company/cercle-promodul/" target="_blank" rel="noopener" aria-label="LinkedIn" title="LinkedIn">
        <i class="icon-linkedin"></i>
      </a>
    </li>
    <li class="link-item">
      <a class="link" href="https://twitter.com/CPromodul_INEF4" target="_blank" rel="noopener" aria-label="Twitter" title="Twitter">
        <i class="icon-twitter"></i>
      </a>
    </li>
    <li class="link-item">
      <div id="dropdown-share-network" text="Partager" variant="link" right class="dropdown-custom">
        <div class="drop-items-wrapper" @click="showSocialShare" v-click-outside="hideSocialShare" @mouseleave="hideSocialShare">

          <div class="drop-items" v-if="isShowSocialShare">
            <div class="drop-item" v-for="network in networks" :key="network.network" @click="hideSocialShare">
              <ShareNetwork :network="network.network" :style="{ backgroundColor: network.color }" :url="sharing.url" :title="sharing.title" :description="sharing.description" :quote="sharing.quote"
                :hashtags="sharing.hashtags" :twitterUser="sharing.twitterUser">
                <i :class="network.icon" class="me-2"></i>
                <span class="pl-1">{{ network.name }}</span>
              </ShareNetwork>
            </div>
          </div>
          <div class="btn-share-social"><i class="icon-share"></i></div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
  import vClickOutside from 'click-outside-vue3';
  import socialNetworkShareData from "./json/socialNetworkShareData.json";

  export default {
    directives: {
      clickOutside: vClickOutside.directive
    },
    data() {
      return {
        isShowSocialShare: false,
        sharing: {
          url: window.location.href.toString().replace('/#/', '/#'),
          title: socialNetworkShareData.title,
          description: socialNetworkShareData.description,
          quote: socialNetworkShareData.quote,
          hashtags: socialNetworkShareData.hashtags,
          twitterUser: socialNetworkShareData.twitterUser,
        },
        networks: [
          { network: "email", name: "Email", icon: "bi bi-envelope" },
          {
            network: "facebook",
            name: "Facebook",
            icon: "bi bi-facebook",
          },
          {
            network: "linkedin",
            name: "LinkedIn",
            icon: "bi bi-linkedin",
          },
          {
            network: "twitter",
            name: "Twitter X",
            icon: "bi bi-twitter-x",
          },
          {
            network: "whatsapp",
            name: "Whatsapp",
            icon: "bi bi-whatsapp",
          },
        ],
      };
    },
    methods: {
      showSocialShare() {
        this.isShowSocialShare = true;
      },
      hideSocialShare() {
        this.isShowSocialShare = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import '@/scss/_var_app.scss';
  @import './SocialNetwork.css';



  .links {
    // background-color: pink;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .link-item {
    margin: 0 .3rem;
    padding: 0 .3rem;
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    cursor: pointer;

    a,
    a:visited,
    a:hover {
      color: $app-primary-color;
      text-decoration: none;
    }

    a::after {
      content: '' !important;
    }
  }

  .link-item-separator {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    margin-left: .8rem;
    padding-right: .8rem;
  }

  .app-icon {
    color: #fd7e14;
    transition: 0.3s;
    // background-color: red;
  }

  .app-icon:hover {
    transform: scale(1.7);
    // background-color: darkblue;
    // color:#fff;
    // border-radius:5px;
    // padding: 2px;
  }

  .dropdown-custom {

    .drop-items-wrapper {
      position: relative;

      // background-color: red;
      .drop-items {
        z-index: 10;
        background-color: rgba(255, 255, 255, .9);
        box-shadow: 1px 1px 5px 0px #656565;
        border-radius: 5px;
        position: absolute;
        top: 20px;
        right: 0px;
        width: 200px;
        padding: 20px .3rem;
        // .drop-item {
        //   // background-color: orange;
        // }
      }
    }
  }

  .btn-share-social {
    color: $app-primary-color;
    z-index: 15;
  }

</style>
