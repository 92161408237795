<template>

  <template v-if="showScreenSizeMsg">

    <div class="d-flex flex-column justify-content-center align-items-center p-3" style="width: 100vw; height: 100vh; text-align: justify">
      <div style="width: 300px; margin: 5rem auto 1rem auto">
        <img :src="imgLogo" fluid alt="logo" class="logo" style="width: 100%" />
      </div>
      <div class="alert alert-warning p-3">
        <p>La résolution de votre écran ({{ screenSizes.width }}&nbsp;x&nbsp;{{ screenSizes.height }}) est trop petite pour une bonne expérience avec cette application.</p>
      </div>
    </div>
  </template>

  <template v-else-if="showChangeOrientationMsg">

    <div class="d-flex flex-column justify-content-center align-items-center p-3 text-justify" style="width: 100vw; height: 100vh; text-align: justify">
      <div style="width: 300px; margin: 5rem auto 1rem auto">
        <img :src="imgLogo" fluid alt="logo" class="logo" style="width: 100%" />
      </div>
      <div class="alert alert-info p-3">
        <p>Veuillez changer l'orientation de <strong>votre écran en mode paysage</strong> pour une meilleure expérience avec cette application.</p>

        <icon-rotation-screen />
        <p style="text-align: center" class="mt-5">Résolution détectée : ({{ screenSizes.width }}&nbsp;x&nbsp;{{ screenSizes.height }})</p>
      </div>
    </div>
  </template>

  <template v-else>

    <div id="app-main">
      <header class="header-main"><!-- :class="'header-' + screenSize.name" -->
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-xl-2 text-center d-flex flex-column justify-content-center align-items-center">
              <router-link :to="{ 'name': 'home' }">
                <img :src="imgLogo" fluid alt="logo" class="logo" />
              </router-link>
              <small class="logo-by-promodul">by LAB PROMODUL</small>
            </div>
            <div class="col-sm-12 col-xl-7">
              <h1 class="title">La lumière naturelle facile</h1>
              <h2 class="sub-title">Optimiser les accès à la lumière naturelle dans le bâtiment</h2>
            </div>
            <!-- style=" display: flex; align-items: center; justify-content: flex-end;" -->
            <div class="col-sm-12 col-xl-3 d-flex align-items-center justify-content-center justify-content-xl-end">
              <!-- <small>Social Pictos [TODO]</small> -->
              <social-network></social-network>
            </div>
          </div>
        </div>
      </header>
      <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link :to="{name: 'project'}">Project</router-link> |
  </nav> -->
      <main class="container">
        <div class="w-100">
          <router-view />
        </div>

      </main>


      <footer class="footer mt-5" id="theFooter">
        <!-- screenSize: {{ screenSize }} -->
        <div class="container">
          <div class="m-2 row">
            <div class="col-12 col-lg-3 text-center my-2">
              <img src="@/assets/logo_fdcp.png" alt="Cercle Promodul / INEF4" class="logo" style="width:200px" />
            </div>
            <div class="col-12 col-lg-9 text-center- text-muted my-2">
              <p><strong>&copy;&nbsp;{{ currentYear }} <a href="https://cercle-promodul.inef4.org" target="_blank">Cercle Promodul / INEF4</a> - v{{ versionString }}</strong><br>
                Cet outil à vocation pédagogique, et pour l'utilisation du plus grand nombre, a été conçu et développé par le <a href="https://cercle-promodul.inef4.org" target="_blank">Fonds de
                  Dotation Cercle Promodul / INEF4</a> dans le cadre d'un <a href="https://cercle-promodul.inef4.org/nos-soutiens/" target="_blank">projet d'action soutenu par le Mécénat</a>. Son
                utilisation est gratuite et n'est pas destinée à un usage commercial.</p>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between align-items-center mx-5" v-if="isShowDebugMode">
          <div class="form-check form-switch">
            <input class="form-check-input" type="checkbox" role="switch" id="DebugBtn" v-model="isDebug">
            <label class="form-check-label" for="DebugBtn">Debug</label>
          </div>
        </div>

      </footer>
    </div>

    <HelpModalBox></HelpModalBox>
  </template>
</template>

<script>
  import { mapGetters } from 'vuex';
  import imgLogo from '@/assets/logo.png';
  import HelpModalBox from '@/components/HelpModalBox.vue';
  import SocialNetwork from '@/components/Header/SocialNetwork.vue';
  import IconRotationScreen from '@/components/IconRotationScreen.vue';
  export default {
    components: {
      HelpModalBox,

      SocialNetwork,
      IconRotationScreen
    },
    data() {
      return {
        isActiveDebugMode: false,
        isShowDebugMode: false,
        isDebug: false,
        imgLogo: imgLogo,
        version: { maj: process.env.VUE_APP_VERSION_NUM_MAJ, min: process.env.VUE_APP_VERSION_NUM_MIN, rev: process.env.VUE_APP_VERSION_NUM_REV },
        screenSizes: {
          width: null,
          height: null
        },
        showScreenSizeMsg: false,
        showChangeOrientationMsg: false
      };
    },
    beforeMount() {
      this.$store.state.simu.version = this.version;
    },
    mounted() {
      this.setScreenSize();
      this.handleDeviceOrientation();
      // console.log(screen);
      if (screen && screen.orientation) {
        screen.orientation.addEventListener('change', this.handleDeviceOrientation);
      }
      window.addEventListener("deviceorientation", this.handleDeviceOrientation);
      window.addEventListener("resize", this.handleDeviceOrientation);

      //--- ACTIVE DEBUG MODE
      document.getElementById('theFooter').addEventListener('mouseenter', (event) => {
        this.isActiveDebugMode = true;
      });
      document.getElementById('theFooter').addEventListener('mouseleave', (event) => {
        this.isActiveDebugMode = false;
      }); 0;

      /*
       Pour activer le débug mode faire : 
       - survoler le footer/num de version
       - Ctrl / Shift / '+' simultanément 
       - password : lum+
      */
      window.addEventListener("keydown", (event) => {
        // console.log('event', event.key, event.ctrlKey, event.shiftKey, this.isActiveDebugMode, event.code);
        // DEBUG Mode : Ctrl + Shift + '+'
        if (this.isActiveDebugMode && event.key === '+' && event.ctrlKey && event.shiftKey) {
          const passDebugMode = prompt('pass debug mode ?');
          if (passDebugMode === 'lum+') {
            this.isShowDebugMode = true;
          }
        }
      });
      //--- /ACTIVE DEBUG MODE

      // if (this.$matomo && this.$matomo.trackPageView()) {
      //   console.log("MATOMO", this.$matomo);
      // }
      if (window._paq) {
        window._paq.push(['trackPageView']);
      }

    },
    computed: {
      ...mapGetters(['isProdMod']),
      versionString() {
        return `${this.version.maj}.${this.version.min}.${this.version.rev}`;
      },
      currentYear() {
        return new Date().getFullYear();
      }
    },
    methods: {
      setScreenSize() {
        this.screenSizes = {
          height: window.innerHeight,
          width: window.innerWidth
        };
      },
      handleDeviceOrientation(e) {
        this.setScreenSize();


        const maxSize = Math.max(this.screenSizes.width, this.screenSizes.height);
        // console.log("handleDeviceOrientation", this.screenSizes, maxSize, e);

        if (maxSize < 350) {
          // console.log('A');
          this.showChangeOrientationMsg = false;
          this.showScreenSizeMsg = true;
        } else if (this.screenSizes.width < this.screenSizes.height /*&& this.screenSizes.width < 550*/) {
          // console.log('B');
          this.showChangeOrientationMsg = true;
          this.showScreenSizeMsg = false;
        } else {
          // console.log('C');
          this.showChangeOrientationMsg = false;
          this.showScreenSizeMsg = false;
        }
      }
    },
    watch: {
      isDebug(val) {
        this.$store.state.isDebug = val;
      }
    }
  };
</script>

<style lang="scss" scoped>

  nav {
    padding: 30px;
    background-color: rgb(198, 246, 250);

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
</style>
