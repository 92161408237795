<template>
        <div class="ic-wrapper">
          <i class="bi bi-tablet-landscape ic-tablet"></i>
          <!-- <i class="bi bi-arrow-clockwise ic-rotation"></i> -->
        </div>
</template>

<style lang="scss" scoped>
.ic-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;

  .ic-tablet {
    position: absolute;
    font-size: 3rem;
    animation: tabletrotation 3s infinite;
  }

  // .ic-rotation {
  //   position: absolute;
  //   font-size: 4rem;
  // }
}


@keyframes tabletrotation {
  0% {
    rotate: -90deg;
    opacity: 0;
  } 
  10% {
    rotate: -90deg;
    opacity: 1;
  }
  80% {
    rotate: 0deg;
    opacity: 1;
  }
  100% {
    rotate: 0deg;
    opacity: 0;
  }
  
}

</style>