<template>
<div>
  <p>
    Plus la surface vitrée est importante et plus les zones où l'éclairage
    naturel est efficace en journée seront grandes. Toutefois, il faut aussi
    évaluer jusqu'à quelle profondeur du local cet éclairage est effectif.
  </p>

  <p>
    Plus la fenêtre est élevée, (grande hauteur ou située proche du plafond)
    et plus la zone éclairée naturellement est profonde. A surface égale,
    l'efficacité lumineuse d'une fenêtre est maximale si elle est située le plus
    près de la partie supérieure de la paroi.
  </p>
    <div class="d-flex justify-content-center flex-wrap flex-xl-nowrap">
      <figure>
        <figcaption>Surface effective d'une fenêtre positionnée en bas/milieu de mur</figcaption>
        <img
          src="@/assets/img/helps/SurfaceEffectiveLumNat_1.png"
          alt="Angle de vision"
          class="img-fluid"
          style="margin: 0.5rem; max-width: 450px"
        />
      </figure>
      <figure>
        <figcaption>Surface effective d'une fenêtre positionnée en haut de mur</figcaption>
        <img
          src="@/assets/img/helps/SurfaceEffectiveLumNat_2.png"
          alt="Distance de vue"
          class="img-fluid"
          style="margin: 0.5rem; max-width: 450px"
        />
      </figure>
    </div>
</div>
</template>
