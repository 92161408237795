<template>
  <div>
    <p>
      La lumière disponible dépend de l'environnement direct du bâtiment selon
      le relief du terrain, les constructions voisines, la végétation, la
      réflexion du sol et de l'environnement, etc.
    </p>
    <p>
      Les ouvertures (parois vitrées, baies) dans un bâtiment assurent le lien
      avec l'environnement extérieur. Une vue dégagée d'un paysage naturel est
      préférée à un environnement urbanisé car moins monotone et plus dynamique.
    </p>

    <p>
      D'autre part, selon le type d'environnement, une vue dégagée est plus
      appréciée qu'une vue restreinte. Une vue diversifiée et dynamique suscite
      plus d'intérêt qu'une vue monotone. Une vue donnant sur un paysage naturel
      peut avoir une influence positive sur le sentiment de bien-être des
      individus et satisfaction professionnelle.
    </p>
  </div>
</template>
