<template>
  <div>
    <!-- <h3>Qualité de la vue extérieure</h3> -->
    <p>
      On parle de la qualité et de l'influence de l'environnement existant
      (relief, construction voisines et leur hauteur / distance, présences de
      surfaces réfléchissantes, végétation) mais aussi du bâtiment existant en
      lui-même (muret, casquette).
    </p>

    <p>
      Pour information, la norme EN 17037 tient compte de la largeur et de la
      distance de vue sur l'extérieur, ainsi que des composantes de paysage
      (ciel, paysage et sol). Elle recommande que la vue soit perçue comme étant
      claire et non déformée, et à un angle minimum de 14°.
    </p>

    <div class="d-flex justify-content-center flex-wrap flex-xl-nowrap">
      <figure>
        <figcaption>Angle de vision</figcaption>
        <img
          src="@/assets/img/helps/QualiteVueExt_Angle.png"
          alt="Angle de vision"
          class="img-fluid"
          style="margin: 0.5rem"
        />
      </figure>
      <figure>
        <figcaption>Distance de vue</figcaption>
        <img
          src="@/assets/img/helps/QualiteVueExt_Distance.png"
          alt="Distance de vue"
          class="img-fluid"
          style="margin: 0.5rem"
        />
      </figure>
    </div>

    <section style="width: 70%; min-width: 380px; margin: 1rem auto">
      <div class="d-flex justify-content-evenly align-items-center w-17">
        <span class="point point-1">
          <i class="bi bi-circle-fill"></i> Minimum
        </span>

        <span class="point point-2">
          <i class="bi bi-circle-fill"></i> Moyen
        </span>

        <span class="point point-3">
          <i class="bi bi-circle-fill"></i> Elevé
        </span>
      </div>
    </section>

    <!-- <section style="width: 70%; min-width: 380px; margin: 1rem auto">
      <h3>Vues sur l'extérieur</h3>

      <div class="row">
        <div class="col-12 col-md-8">
          <table class="table caption-top" id="VueExtComponentsTable">
            <caption>
              Les différentes composantes de la vue extérieure
            </caption>
            <thead>
              <tr>
                <th></th>
                <th>Minimum</th>
                <th>Moyen</th>
                <th>Elevé</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Ciel</th>
                <td></td>
                <td>
                  <span class="point point-2"
                    ><i class="bi bi-circle-fill"></i
                  ></span>
                </td>
                <td>
                  <span class="point point-3"
                    ><i class="bi bi-circle-fill"></i
                  ></span>
                </td>
              </tr>
              <tr>
                <th>Paysage</th>
                <td>
                  <span class="point point-1"
                    ><i class="bi bi-circle-fill"></i
                  ></span>
                </td>
                <td>
                  <span class="point point-2"
                    ><i class="bi bi-circle-fill"></i
                  ></span>
                </td>
                <td>
                  <span class="point point-3"
                    ><i class="bi bi-circle-fill"></i
                  ></span>
                </td>
              </tr>
              <tr>
                <th>Sol</th>
                <td></td>
                <td>
                  <span class="point point-2"
                    ><i class="bi bi-circle-fill"></i
                  ></span>
                </td>
                <td>
                  <span class="point point-3"
                    ><i class="bi bi-circle-fill"></i
                  ></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12 col-md-4 d-flex align-items-end">
          <div class="arrows-wrapper">
            <div class="arrow-block arrow-1"><SvgArrow class="arrow" /></div>
            <div class="arrow-block arrow-2"><SvgArrow class="arrow" /></div>
            <div class="arrow-block arrow-3"><SvgArrow class="arrow" /></div>
          </div>
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import SvgArrow from "@/components/SvgArrow.vue";
export default {
  data() {
    return {};
  },
  components: { SvgArrow },
};
</script>

<style lang="scss" scoped>
.arrows-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .arrow-block {
    &.arrow-1 {
      .arrow {
        height: 70px;
        fill: orange;
      }
    }
    &.arrow-2 {
      .arrow {
        height: 100px;
        fill: orangered;
      }
    }
    &.arrow-3 {
      .arrow {
        height: 130px;
        fill: skyblue;
      }
    }
  }
}

#VueExtComponentsTable {
  thead {
    th {
      text-align: center;
    }
  }
  td {
    text-align: center;
    span {
      display: block;
      width: 100%;
      text-align: center;
      // background-color: pink;
    }
  }
}

.point {
  &.point-1 .bi {
    color: orange;
  }
  &.point-2 .bi {
    color: orangered;
  }
  &.point-3 .bi {
    color: skyblue;
  }
}
</style>
