import { createRouter, createWebHistory } from "vue-router";
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/AppIntro.vue"),
    meta: { title: "LUMIERE + | Accueil" },
  },
  {
    path: "/project",
    name: "project",
    component: () => import("../views/AppProject.vue"),
    meta: { title: "LUMIERE + | Projet" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
