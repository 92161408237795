import translate from '@/json/translate.json';
import months from '@/json/months.json';

export function getTranslation (word, isCapitalize = false) {
  // console.log(translate);
  // console.log(word);
  let trans = translate[word];

  if (isCapitalize) {
    trans = capitalize(trans);
  }
  // console.log(trans);
  return trans;
}

function capitalize (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function getMonthLabel (monthIndex, type = 'short', isCapitalize = false) {
  let month = months[type][monthIndex];
  if (isCapitalize) {
    month = capitalize(month);
  }
  // console.log(month);
  return month;
}
