<template>
  <div>
    <!-- <h6>Risque d'éblouissement</h6> -->
    <p>
      Cet indicateur a pour but de déterminer les risques d'éblouissement (voire
      les risques de gains thermiques excessifs) selon les expositions des
      ouvertures. Ce risque est défini si l'éclairement calculé est strictement
      supérieur à 10 fois la valeur de l'éclairement moyen.
    </p>

    <p>
      La méthode reste assez empirique et est basée sur un seuil maximum
      d'autonomie lumineuse à ne pas dépasser en période d'occupation. Cela a le
      mérite d'évaluer les situations pour lesquelles trop de lumière naturelle
      pénètrerait dans le bâtiment et donne une idée des pièces ou locaux dans
      lesquels pourraient se poser ce problème.
    </p>

    <p>
      La valeur idéale serait de 0%. Au-delà de 5%, il est
      considéré que des risques d'éblouissement se posent. D'un strict point de
      vue, seules les ouvertures sur des parois nord échapperaient à ce
      problème.
    </p>
  </div>
</template>
