<template>
  <div>
    <p>
      Le rayonnement solaire direct en m² désigne la surface de la pièce ou du
      local qui est balayée directement par les rayons du soleil.
    </p>

    <p>
      On peut retenir qu'il s'agit du potentiel maximum de surface possible qui
      peut être affecté quotidiennement selon les conditions météo dynamiques.
    </p>

    <p>
      Cette surface est relative et doit être mise en perspective avec la
      surface totale du local ou de la pièce pour évaluer cette donnée.
    </p>

    <p>
      Les calculs sont effectués au pas de temps horaire à partir de fichiers
      météo statistiques sur les dernières années permettant de dégager une
      moyenne mensuelle et annuelle.
    </p>
  </div>
</template>
