<template>
  <div>
    <!-- <h3>Apport en lumière naturelle</h3> -->
    <p>
      La quantité de lumière naturelle dans une pièce est le nombre de lux
      suffisant (seuil de luminosité suffisant) à l'œil humain pour effectuer
      des tâches sans avoir à allumer la lumière artificielle tout en couvrant
      une surface suffisante de la pièce.
    </p>

    <div>
      Pour information, la norme EN&nbsp;17037 recommande que les bâtiments disposent
      de :
      <ul>
        <li>
          Un niveau d'éclairement d'au moins 100 lux couvrant au moins 95% de la
          surface pendant au moins la moitié des heures de jour sur l'année.
        </li>
        <li>
          Ouvertures en toiture : Un niveau d'éclairement d'au moins 300 lux
          couvrant au moins 95% de la surface pendant au moins la moitié des
          heures de jour sur l'année.
        </li>
      </ul>
    </div>

    <p>
      La méthode de calcul utilisée dans l'outil est inspirée de la norme EN&nbsp;17037 mais ne reprend pas intégralement tous les éléments de calcul.
    </p>

    <div
      class="d-flex justify-content-center align-items-center flex-wrap flex-xl-nowrap"
    >
      <figure>
        <figcaption></figcaption>
        <img
          src="@/assets/img/helps/ApportLumNat_Facade.png"
          alt="Apport lumière naturelle en façades"
          class="img-fluid"
        />
      </figure>
      <figure>
        <figcaption></figcaption>
        <img
          src="@/assets/img/helps/ApportLumNat_Toiture.png"
          alt="Apport lumière naturelle en toiture"
          class="img-fluid"
        />
      </figure>
      <!-- <img src="@/assets/img/helps/ApportLumNat_Facade.png" alt="" class="img-fluid" />
      <img src="@/assets/img/helps/ApportLumNat_Toiture.png" alt="" class="img-fluid" /> -->
    </div>
  </div>
</template>
