<template>
  <div>
    <!-- <h3>Pourquoi des données annuelles ?</h3> -->

    <p>
      La situation géographique du bâtiment, l'emplacement des ouvrants dans la
      pièce et les saisons ont une incidence sur le rayonnement solaire reçu
      tout au long de l'année.
    </p>

    <p>
      Il est donc important d'avoir une vision générale sur l'année mais
      également par mois et jour (en fonction des saisons).
    </p>
  </div>
</template>
