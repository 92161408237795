//-- POUR LE DEV et la période de test
const appVersionMaj = process.env.VUE_APP_VERSION_NUM_MAJ;
const appVersionMin = process.env.VUE_APP_VERSION_NUM_MIN;
const appVersionRev = process.env.VUE_APP_VERSION_NUM_REV;
const appMode = process.env.VUE_APP_MODE;
const currentVersion = { maj: appVersionMaj, min: appVersionMin, rev: appVersionRev };
let lastVersion = undefined;

try {
  lastVersion = JSON.parse(localStorage.getItem("lumiere+_version"));
  console.log("currentVersion:", currentVersion, "lastVersion:", lastVersion);
} catch (e) {
  console.log("Last Version Error", e);
}

if (!lastVersion || (currentVersion?.maj !== lastVersion?.maj && currentVersion?.min !== lastVersion?.min)) {
  localStorage.clear();
  if (appMode !== "production") {
    alert("Le store a été réinitialisé, suite à la détection d'une nouvelle version !");
  }
  localStorage.setItem("lumiere+_version", JSON.stringify({ maj: appVersionMaj, min: appVersionMin, rev: appVersionRev }));
  window.document.location.reload();
}
//--

import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import VueGtag from "vue-gtag";
import VueMatomo from "vue-matomo";

import VueSocialSharing from "vue-social-sharing";

import Toast, { useToast, POSITION } from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import "@popperjs/core";
// import 'bootstrap';
import "bootstrap-icons/font/bootstrap-icons.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

import "./scss/app.scss";

const app = createApp(App);

//-- ICONS
import IconBase from "@/components/Icons/IconBase.vue";
app.component("IconBase", IconBase);
import IconLogoCerclePromodul from "./components/Icons/IconLogoCerclePromodul.vue";
app.component("IconLogoCerclePromodul", IconLogoCerclePromodul);
import IconLogoLeLab from "./components/Icons/IconLogoLeLab.vue";
app.component("IconLogoLeLab", IconLogoLeLab);
//-- /ICONS

//-- AIDES
import HelpBtn from "@/components/HelpButton.vue";
app.component("HelpBtn", HelpBtn);

import IntroSavoirPlusConfortLumineux from "@/components/Helps/IntroSavoirPlusConfortLumineux.vue";
app.component("IntroSavoirPlusConfortLumineux", IntroSavoirPlusConfortLumineux);
import IntroSavoirPlusOutil from "@/components/Helps/IntroSavoirPlusOutil.vue";
app.component("IntroSavoirPlusOutil", IntroSavoirPlusOutil);
import ProjectDepartment from "@/components/Helps/ProjectDepartment.vue";
app.component("ProjectDepartment", ProjectDepartment);
import RoomTypeOfView from "@/components/Helps/RoomTypeOfView.vue";
app.component("RoomTypeOfView", RoomTypeOfView);
import EnvironnementBati from "@/components/Helps/EnvironnementBati.vue";
app.component("EnvironnementBati", EnvironnementBati);
import SurfaceEffectiveLumNat from "@/components/Helps/SurfaceEffectiveLumNat.vue";
app.component("SurfaceEffectiveLumNat", SurfaceEffectiveLumNat);
import MasquesProches from "@/components/Helps/MasquesProches.vue";
app.component("MasquesProches", MasquesProches);
import WallsColors from "@/components/Helps/WallsColors.vue";
app.component("WallsColors", WallsColors);
import HelpWindowTypes from "@/components/Helps/WindowTypes.vue";
app.component("HelpWindowTypes", HelpWindowTypes);
import MasquesLointainsTypeSaisieSimple from "@/components/Helps/MasquesLointainsTypeSaisieSimple.vue";
app.component("MasquesLointainsTypeSaisieSimple", MasquesLointainsTypeSaisieSimple);
import MasquesLointainsTypeSaisieCustom from "@/components/Helps/MasquesLointainsTypeSaisieCustom.vue";
app.component("MasquesLointainsTypeSaisieCustom", MasquesLointainsTypeSaisieCustom);

//-- RES BILAN
import ResBilanSituationGeo from "@/components/Helps/ResBilanSituationGeo.vue";
app.component("ResBilanSituationGeo", ResBilanSituationGeo);
import ResBilanRayoDirectDiffuRef from "@/components/Helps/ResBilanRayoDirectDiffuRef.vue";
app.component("ResBilanRayoDirectDiffuRef", ResBilanRayoDirectDiffuRef);
import ResBilanNormeEN17037 from "@/components/Helps/ResBilanNormeEN17037.vue";
app.component("ResBilanNormeEN17037", ResBilanNormeEN17037);
import ResBilanApportLumNat from "@/components/Helps/ResBilanApportLumNat.vue";
app.component("ResBilanApportLumNat", ResBilanApportLumNat);
import ResBilanQualiteVueExt from "@/components/Helps/ResBilanQualiteVueExt.vue";
app.component("ResBilanQualiteVueExt", ResBilanQualiteVueExt);
import ResBilanEblouissement from "@/components/Helps/ResBilanEblouissement.vue";
app.component("ResBilanEblouissement", ResBilanEblouissement);
import ResBilanExpoRayoDirect from "@/components/Helps/ResBilanExpoRayoDirect.vue";
app.component("ResBilanExpoRayoDirect", ResBilanExpoRayoDirect);
import ResBilanAccesLumEtRepart from "@/components/Helps/ResBilanAccesLumEtRepart.vue";
app.component("ResBilanAccesLumEtRepart", ResBilanAccesLumEtRepart);
import ResAutonomieLumineuse from "@/components/Helps/ResAutonomieLumineuse.vue";
app.component("ResAutonomieLumineuse", ResAutonomieLumineuse);
import ResEclairementUtiliseLumJour from "@/components/Helps/ResEclairementUtiliseLumJour.vue";
app.component("ResEclairementUtiliseLumJour", ResEclairementUtiliseLumJour);
import ResRisqueEblouissement from "@/components/Helps/ResRisqueEblouissement.vue";
app.component("ResRisqueEblouissement", ResRisqueEblouissement);

import ResChartsDonneesAnnuelles from "@/components/Helps/ResChartsDonneesAnnuelles.vue";
app.component("ResChartsDonneesAnnuelles", ResChartsDonneesAnnuelles);
import ResChartsUniteLux from "@/components/Helps/ResChartsUniteLux.vue";
app.component("ResChartsUniteLux", ResChartsUniteLux);

import ResChartsHeureRayonnement from "@/components/Helps/ResChartsHeureRayonnement.vue";
app.component("ResChartsHeureRayonnement", ResChartsHeureRayonnement);
import ResChartsProfondeurRayonnement from "@/components/Helps/ResChartsProfondeurRayonnement.vue";
app.component("ResChartsProfondeurRayonnement", ResChartsProfondeurRayonnement);
import ResChartsSurface from "@/components/Helps/ResChartsSurface.vue";
app.component("ResChartsSurface", ResChartsSurface);

//--RES PRECO
import ResLumNatEnergRenouv from "@/components/Helps/ResPrecoLumNatEnergRenouv.vue";
app.component("ResLumNatEnergRenouv", ResLumNatEnergRenouv);
import ResConfortLumOrientations from "@/components/Helps/ResPrecoConfortLumOrientations.vue";
app.component("ResConfortLumOrientations", ResConfortLumOrientations);
import ResConfortLumTypesOuvrants from "@/components/Helps/ResPrecoConfortLumTypesOuvrants.vue";
app.component("ResConfortLumTypesOuvrants", ResConfortLumTypesOuvrants);

import DummyHelp from "@/components/Helps/DummyHelp.vue";
app.component("DummyHelp", DummyHelp);
//-- /AIDES

//DIRECTIVES
//Register the directive + functionality, (ex : <span v-dashed-border="'#f00'">Foo</span>)
// app.directive("dashed-border", {
//   beforeMount: (el, { value }) => {
//     el.style.border = `1px dashed ${value}`;
//   },
// });

import mitt from "mitt";
const emitter = mitt();
app.config.globalProperties.emitter = emitter;

const publicPath = process.env.BASE_URL;
app.config.globalProperties.publicPath = publicPath;

//-- TRANSLATE
import { getTranslation } from "./composition/translate.js";
app.config.globalProperties.getTranslation = getTranslation;
//--

router.beforeEach((to, from, next) => {
  document.title = to.meta.title || "LUMIERE +";
  next();
});

if (appMode === "production") {
  app.use(VueGtag, {
    config: { id: "G-2CJE6FQD10" },
  });

  app.use(VueMatomo, {
    // Configure your matomo server and site by providing
    host: "https://matomo.inef4.org/",
    siteId: 7,
    router: router,
    // enableLinkTracking: true,
    // trackInitialView: true,
  });
}

//USE
app.use(router);
app.use(store);
app.use(Toast, {
  position: POSITION.BOTTOM_RIGHT,
});
app.use(VueSocialSharing);
app.config.globalProperties.myToast = useToast();

// MOUNT THE APP
app.mount("#app");

// createApp(App).use(store).use(router).mount('#app')
