<template>
  <div>
    <!-- <h3>Exposition au rayonnement direct</h3> -->

    <p>
      On le sait, la lumière du jour peut avoir des effets bénéfiques sur la
      santé, par exemple : employés plus heureux et plus productifs, élèves plus
      attentifs, effets bénéfiques dans les hôpitaux. Mais ces effets bénéfiques
      se vérifient si les niveaux d'exposition sont aussi maîtrisés durant les
      mois d'été afin d'éviter les points chauds et les températures excessives.
    </p>

    <p>
      Pour le bien-être des occupants, la norme EN&nbsp;17037 fixe donc une durée
      d'exposition à l'ensoleillement direct à l'intérieur du local (nombre
      d'heure suffisant par jour de soleil direct reçu dans la pièce), d'au
      moins 1,5 heures par jour en période hivernale comprise entre le 1er février et 21 mars.
    </p>

      <figure>
        <figcaption></figcaption>
        <img
          src="@/assets/img/helps/ExpoRayoDirect.png"
          alt="Exposition au rayonnement direct"
          class="img-fluid"
        />
      </figure>
  </div>
</template>
