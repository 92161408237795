<template>
  <div>
    <!-- <h3>qualité du confort lumineux selon les typologies douvrants</h3> -->
    <div class="d-flex justify-content-start align-items-start flex-wrap flex-md-nowrap">
      <img src="@/assets/img/helps/typeOuvrant1.png" alt="" class="img-fluid" style="width: 130px; margin: 1rem">
      <div>
        <p><strong>Les ouvertures latérales (verticales)</strong> ne voient qu'une partie du ciel.<br>Par ciel couvert, ces ouvertures verticales ont des performances lumineuses nettement plus faibles que les ouvertures horizontales.</p>
        <p>Les fenêtres latérales en façade sud transmettent un maximum de rayons solaires en hiver, ce qui favorise l'utilisation des gains solaires, tout en limitant les pénétrations estivales et les surchauffes qu'elles induisent.</p>
      </div>
    </div>

    <div class="d-flex justify-content-start align-items-start flex-wrap  flex-md-nowrap">
      <img src="@/assets/img/helps/typeOuvrant2.png" alt="" class="img-fluid" style="width: 130px; margin: 1rem">
      <div>
        <p><strong>Les ouvertures zénithales</strong> (et sur pans inclinés, comme les fenêtres de toit) s'ouvrent sur la totalité (ou une partie) de la voute céleste, et induisent un large accès à la pénétration de lumière diffuse, beaucoup plus homogène que celle produite par une ouverture verticale.</p>
        <p>La lumière entre dans les locaux par le plafond, limitant à priori les phénomènes d'éblouissement. Cependant, les ouvertures zénithales captent mal les rayons solaires d'hiver alors qu'elles laissent plus largement pénétrer le soleil d'été, pouvant engendrer, à défaut de protections appropriées, des risques d'élévation de température dans les locaux et d'inconfort d'été.</p>
      </div>
    </div>
  </div>
</template>