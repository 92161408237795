<template>
  <!-- <h1>WallsColors</h1> -->
  <div>
    <p>
      Une bonne diffusion de la lumière nécessitera l'utilisation de parois de
      couleurs plutôt claires. Avec des couleurs plus claires, la lumière sera
      mieux réfléchie atteindra plus facilement les parties opposées aux
      ouvertures.
    </p>

    <ul>
      <li>
        Les tendances claires comprennent le blanc, le jaune, les carrelages
        clair ou le bois clair
      </li>
      <li>
        Les tendances neutres comprennent le gris, le vert (sauf vert foncé),
        les teintes colorées pastels
      </li>
      <li>
        Les tendances foncées comprennent le brun, le bleu et le vert (foncés) ,
        le rouge et le noir
      </li>
    </ul>
  </div>
</template>
