<template>
  <div>
    <!-- <h3>Distribution lumineuse, proportions du local et vue sur l'extérieur </h3> -->

    <p>
      L'accès à la lumière naturelle dans un local doit toujours être pensée
      (pour la construction) ou adaptée (en rénovation) en fonction des périodes
      d'occupation des locaux. Leur orientation et la course du soleil auront un
      effet important.
    </p>

    <p>
      Les notions d'autonomie lumineuse permettent une première approche et les
      indicateurs associés donnent des éléments significatifs en termes de
      confort et de qualité de la lumière du jour.
    </p>

    <p>
      Par ailleurs les fenêtres verticales, jouent un rôle important quant à la
      profondeur jusqu'à laquelle le niveau d'éclairement peut être considéré
      comme efficace, c'est-à-dire avoir un seuil d'éclairement utile permettant
      de se passer d'un éclairage artificiel.
    </p>

    <div class="d-flex justify-content-center flex-wrap flex-xl-nowrap">
      <figure>
        <!-- <figcaption></figcaption> -->
        <img
          src="@/assets/img/helps/DistributionLum1.png"
          alt="Angle de vision"
          class="img-fluid"
          style="margin: 0.5rem; max-width: 450px"
        />
      </figure>
      <figure>
        <!-- <figcaption></figcaption> -->
        <img
          src="@/assets/img/helps/DistributionLum2.png"
          alt="Distance de vue"
          class="img-fluid"
          style="margin: 0.5rem; max-width: 450px"
        />
      </figure>
    </div>
  </div>
</template>
