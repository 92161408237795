<template>
  <div>
    <!-- <h1>Type de fenêtres</h1> -->

    <p>
      La forme de la fenêtre et de la menuiserie a un effet sur la surface
      transparente permettant l'accès au rayonnement solaire. Les dimensions des
      fenêtres sont généralement définies hors tout c'est-à-dire la longueur et
      la largeur maximum menuiseries incluses. Par simplification, dans le cas
      des bâtiment existants, il est possible de mesurer la dimension de
      l'ouverture dans la paroi. La précision sur le type de menuiserie permet
      de déterminer par approche la surface du vitrage.
    </p>

    <div class="row">
      <div class="col-12 col-md-6">
        <h6>Fêtres et baies</h6>
        <ul class="list-group">
          <li
            v-for="(window, windowIdx) in windowsType.windows"
            :key="`widows${windowIdx}`"
            class="list-group-item"
          >
            <img
              :src="getImgUrl(window.imgName)"
              :alt="window.label"
              class="img-fluid"
              style="width: 120px; margin: 0.5rem"
            />
            {{ window.label }}
          </li>
        </ul>
      </div>
      <div class="col-12 col-md-6">
        <h6>Portes fenêtres</h6>
        <ul class="list-group">
          <li
            v-for="(window, windowIdx) in windowsType.doors"
            :key="`widows${windowIdx}`"
            class="list-group-item"
          >
            <img
              :src="getImgUrl(window.imgName)"
              :alt="window.label"
              class="img-fluid"
              style="width: 120px; margin: 0.5rem"
            />
            {{ window.label }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowsType: {
        windows: [
          { label: "Fenêtre 1 vantail", imgName: "fen-1_vant.png" },
          { label: "Fenêtre 2 vantaux", imgName: "fen-2_vant.png" },
          { label: "Baie vitrée", imgName: "fen_baie.png" },
        ],
        doors: [
          {
            label: "Porte Fenêtre 1 vantail avec sous bassement",
            imgName: "fen-porte-1_vant-av_soub.png",
          },
          {
            label: "Porte Fenêtre 2 vantaux avec sous bassement",
            imgName: "fen-porte-2_vant-av_soub.png",
          },
          {
            label: "Porte Fenêtre 1 vantail sans sous bassement",
            imgName: "fen-porte-1_vant-ss_soub.png",
          },
          {
            label: "Porte Fenêtre 2 vantaux sans sous bassement",
            imgName: "fen-porte-2_vant-ss_soub.png",
          },
        ],
      },
    };
  },
  methods: {
    getImgUrl(pic) {
        return require('@/assets/img/helps/'+pic)
    }
  },
};
</script>
