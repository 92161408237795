<template>
  <div>
    <!-- <h2>L'autonomie lumineuse</h2> -->
    <p>
      Il s'agit de définir pendant combien de temps, sur une durée d'occupation
      théorique du local, le niveau d'éclairement cible est maintenu grâce à la
      lumière naturelle. Il correspond donc à un ratio de temps où la lumière
      naturelle est effective sur la durée totale de présence possible dans le
      local.
    </p>
    <p>
      Ce calcul est réalisé heure par heure sur toute l'année et tient compte
      des données et conditions météo «&nbsp;normées&nbsp;» utilisées dans les
      méthodes de calcul. L'analyse de l'autonomie lumineuse part du principe
      que lorsque la lumière naturelle n'est plus suffisante, l'éclairage
      artificiel est mis en route pour maintenir le niveau d'éclairement seuil
      requis.
    </p>

    <p>
      Le temps d'occupation théorique pour les locaux tertiaires a été retenu
      sur une base de présence de 8h à 18 h et pour le résidentiel de 6h à 22h.
    </p>

    <p>
      Un objectif raisonnable est d'arriver à une autonomie lumineuse,
      c'est-à-dire à un temps d'utilisation de l'éclairage naturel, d'au moins
      50-60 %
    </p>
  </div>
</template>
