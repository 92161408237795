<template>
  <div>
    <p>
      Le nombre d'heures de rayonnement solaire direct désigne la durée pendant
      laquelle la pièce ou le local peut recevoir directement les rayons du
      soleil.
    </p>

    <p>
      On peut retenir qu'il s'agit du potentiel maximum possible d'accès au
      rayonnement solaire.
    </p>

    <p>
      C'est une durée théorique et maximale qui peut être affectée
      quotidiennement selon les conditions météo dynamiques. Les calculs sont
      effectués au pas de temps horaire à partir de fichiers météo statistiques
      sur les dernières années permettant de dégager une moyenne mensuelle et
      annuelle.
    </p>

    <p>
      Pour information la limite maximum a été fixée dans Lumière + à 16h,
      correspondant au nombre d'heures de présence possible de soleil dans le
      ciel au solstice d'été. Quelques départements du sud de la France
      pourraient alors atteindre, selon la disposition des ouvertures, un nombre
      assez proche de ce maximum.
    </p>

    <p>
      Il convient toutefois de rappeler que la moyenne quotidienne sur une année
      du taux d'ensoleillement est d'environ 9h, dans les cas les plus
      favorables.
    </p>
  </div>
</template>
