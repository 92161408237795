<template>
  <div>
      <figure>
        <!-- <figcaption>Masque léger</figcaption> -->
        <img
          src="@/assets/img/helps/schema_masque_lointain_1.png"
          alt="Masque léger"
          class="img-fluid"
          style="margin: 0.5rem; max-width: 800px"
        />
      </figure>

      <figure>
        <!-- <figcaption>Masque moyen</figcaption> -->
        <img
          src="@/assets/img/helps/schema_masque_lointain_2.png"
          alt="Masque moyen"
          class="img-fluid"
          style="margin: 0.5rem; max-width: 800px"
        />
      </figure>

      <figure>
        <!-- <figcaption>Masque important</figcaption> -->
        <img
          src="@/assets/img/helps/schema_masque_lointain_3.png"
          alt="Masque important"
          class="img-fluid"
          style="margin: 0.5rem; max-width: 800px"
        />
      </figure>
  </div>
</template>