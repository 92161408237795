<template>
  <div>
    <p>
      La situation géographique d'une pièce ou d'un local a une incidence sur le
      rayonnement solaire reçu tout au long de l'année, dans un endroit donné.
      La mention du département est alors essentielle pour un calcul des
      résultats le plus précis possible.
    </p>
  </div>
</template>

<script>
export default {
  name: "HelpDummyA",
};
</script>
