<template>
  <div>
    <p>
      La profondeur du rayonnement solaire dans la pièce ou le local désigne,
      lorsque les rayons du soleil peuvent pénétrer dans les locaux, la
      profondeur jusqu'à laquelle ce rayonnement sera perceptible.
    </p>

    <p>
      Cette donnée n'est prise en compte et calculée que pour les fenêtres
      verticales et fenêtre par fenêtre. Elle indique quelle distance opposée à
      la fenêtre, les rayons solaires vont parcourir.
    </p>

    <p>
      Les fenêtres de toit, selon la pente du toit et l'exposition, pourront
      dans la majorité des cas capter un rayonnement solaire qui couvrira toute
      la profondeur de la pièce.
    </p>

    <p>
      Concernant l'éclairage zénithal, le rayonnement solaire, en raison de
      l'horizontalité de l'ouverture vers l'extérieur n'est pas concernée par
      cette notion de profondeur de rayonnement dans la pièce ou local.
    </p>
  </div>
</template>
